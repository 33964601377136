import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
import Wave from './wave';
import { Link } from 'gatsby';
import Barchart from './barchart';
import Counter from './counter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`MDX = Markdown avec du JSX dedans`}</h1>
    <p>{`Ce billet est écrit en MDX. D'ailleurs tout ce site est écrit en MDX, grace au génial `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/"
      }}>{`Gatsby`}</a>{` qui fera peut être l'objet d'un futur billet.   `}</p>
    <p>{`Explications pour MDX:`}</p>
    <h2>{`Markdown`}</h2>
    <p>{`Le `}<a parentName="p" {...{
        "href": "https://fr.wikipedia.org/wiki/Markdown"
      }}>{`markdown`}</a>{` est une idée simple de 2004 qui consiste à utiliser des conventions de formatage encodées dans le corps même du texte qui soient lisibles même sans formatage. Quelques exemples:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`- ** mot ** → mot en gras
- * mot * → mot en italique
- ## Titre → Titre en h2
- * espace → liste de points
- 1. espace → liste numéroté
- [] → checkbox
- tab → quote
- …`}</code></pre></div>
    <p>{`Le texte reste lisible tel quel et peut être transformé en html automatiquement.`}</p>
    <h2>{`JSX est le javascript de React`}</h2>
    <p>{`Le JSX est une extension de javascript dont la syntaxe est de style html et qui permet de définir et d'appeler des composants `}<a parentName="p" {...{
        "href": "https://fr.wikipedia.org/wiki/React_(JavaScript)"
      }}>{`React`}</a>{`.`}</p>
    <h2>{`Comment ça marche ?`}</h2>
    <p>{`Le texte suivant dans un billet markdown`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`import Barchart from './barchart'
<Barchart data={[1, 2, 3, 7, 1, 3]} width={600} height={400} />`}</code></pre></div>
    <br />
se transforme en une image
    <Barchart data={[1, 2, 3, 7, 1, 3]} width={600} height={400} mdxType="Barchart" />
    <h2>{`Un compteur simple`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`import Wave from './wave';
<Wave />`}</code></pre></div>
    <Wave mdxType="Wave" />
    <h2>{`Un compteur plus complexe`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`import Counter from './counter';
<Counter />`}</code></pre></div>
    <Counter mdxType="Counter" />
    <h1>{`par iFrame`}</h1>
    <p>{`Invocation d'une iframe:`}</p>
    <iframe src="/sandbox/quadtree/index.html" width="1000" height="800" frameBorder="0" />
    <h2>{`... conclusion et retour à l'accueil`}</h2>
    <Link to="/" mdxType="Link">Revenir à l'accueil</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      